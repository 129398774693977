import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/72cefc1d/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "knee"
    }}>{`Knee`}</h2>
    <ul>
      <li parentName="ul">{`Ligament injuries`}<ul parentName="li">
          <li parentName="ul">{`ACL repair and reconstruction`}</li>
          <li parentName="ul">{`PCL repair and reconstruction`}</li>
          <li parentName="ul">{`MCL repair and reconstruction`}</li>
          <li parentName="ul">{`LCL repair and reconstruction`}</li>
          <li parentName="ul">{`Revision ligament reconstruction`}</li>
        </ul></li>
      <li parentName="ul">{`Meniscus injuries`}<ul parentName="li">
          <li parentName="ul">{`Repair`}</li>
          <li parentName="ul">{`Debridement`}</li>
          <li parentName="ul">{`Transplant`}</li>
        </ul></li>
      <li parentName="ul">{`Articular cartilage injuries and defects`}<ul parentName="li">
          <li parentName="ul">{`Microfracture`}</li>
          <li parentName="ul">{`Biocartilage`}</li>
          <li parentName="ul">{`Osteochondral allografts`}</li>
          <li parentName="ul">{`Osteochondral autografts`}</li>
          <li parentName="ul">{`Osteochondritis Dissecans lesions`}</li>
        </ul></li>
      <li parentName="ul">{`Patella instability`}<ul parentName="li">
          <li parentName="ul">{`MPFL reconstruction and repair`}</li>
          <li parentName="ul">{`Tibial tubercle osteotomy`}</li>
        </ul></li>
      <li parentName="ul">{`Extensor mechanism injuries`}<ul parentName="li">
          <li parentName="ul">{`Quadriceps tendonitis and tears`}</li>
          <li parentName="ul">{`Patellar tendonitis and tears`}</li>
        </ul></li>
      <li parentName="ul">{`Exertional compartment syndrome`}</li>
      <li parentName="ul">{`Fractures of patella, femur, tibia`}</li>
      <li parentName="ul">{`Osteoarthritis`}<ul parentName="li">
          <li parentName="ul">{`Osteotomies`}</li>
          <li parentName="ul">{`Partial knee replacement`}</li>
          <li parentName="ul">{`Total knee replacement`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      